











































import {
  defineComponent /* , ref */,
  reactive,
  toRefs,
  computed,
  ComputedRef
} from '@vue/composition-api';
import { formatDbDate } from '@/utils';
import { useLoadJobValidationApi, LoadJobValidationModel } from '@/module/api/load-job/validation';
import { useLoadJobApi, LoadJobModel } from '@/module/api/load-job';
import { useDomainApi } from '@/module/api/domain';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';

import Vue from 'vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';

const { open, cancel } = useConfirmation();
const {
  getItem: getLoadJob,
  selectItem: selectLoadJob,
  selectedItem: selectedLoadJob
} = useLoadJobApi();
const {
  getItems: getValidations,
  items: validations,
  selectItem: selectValidation,
  getItem: getValidation,
  updateItem: updateValidation,
  fix: fixValidation
} = useLoadJobValidationApi();

const {
  getItem: getDomain,
  selectItem: selectDomain,
  selectedItem: selectedDomain
} = useDomainApi();

const state = reactive({
  isLoading: false,
  validationGroups: [0],
  domainUuid: '',
  loadJobUuid: ''
});

// Workaround for vue layout typescript linting complaining
const validationsList: ComputedRef<LoadJobValidationModel[]> = computed(() => {
  return validations.value;
});

const validationsByGroup = computed(() => {
  return validations.value?.reduce((validationsByGroup: any, current: LoadJobValidationModel) => {
    if (!Array.isArray(validationsByGroup[`${current.group}`])) {
      validationsByGroup[`${current.group}`] = [];
    }
    validationsByGroup[`${current.group}`].push(current);
    return validationsByGroup;
  }, {});
});

const refreshItems = () => {
  return;
};

const reset = async () => {
  return;
};

const initializeData = async (domainUuid: string, jobUuid: string) => {
  if (domainUuid == '' || jobUuid == '') {
    const errMessage = "Can't initialize data for component without domainuuid and jobuuid";
    Vue.$log.error(errMessage);
    return;
  }
  if (selectedDomain.value?.uuid != domainUuid) {
    await getDomain(domainUuid);
    await selectDomain(domainUuid);
  }
  if (selectedLoadJob.value?.uuid !== jobUuid) {
    await getLoadJob({ uuid: jobUuid, forceRefresh: true });
    await selectLoadJob(jobUuid);
  }
  getValidations({ uuid: jobUuid });
};

const getValidationsInGroup = (group: string): LoadJobValidationModel[] => {
  return validations.value?.filter((item: LoadJobValidationModel) => {
    return item.group === group;
  });
};

export default defineComponent({
  name: 'WorkspaceImportJobDetailValidations',
  components: { RefreshButton, Confirm, DomainWorkspaceToolbar },
  props: {
    uuid: {
      type: String,
      default: ''
    },
    jobUuid: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    state.domainUuid = props.uuid;
    state.loadJobUuid = props.jobUuid;
    initializeData(props.uuid, props.jobUuid);
    return {
      ...toRefs(state),
      reset,
      formatDbDate,
      refreshItems,
      getValidationsInGroup,
      selectedLoadJob,
      dialog: false,
      backLink: `/domain/${props.uuid}/job/import/${props.jobUuid}`,
      validationsList,
      validationsByGroup,
      validationsMenu: [
        {
          title: 'Re-validate',
          link: ''
        },
        { title: 'Validation History', link: '' },
        { title: 'Download as Spreadsheet', link: '' },
        { title: 'Configure', link: '' }
      ]
    };
  }
});
