var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: { subtitle: "Import Job Validation" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [_vm._v(_vm._s(_vm.selectedLoadJob.label) + " ")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        {
          staticClass: "mb-4",
          attrs: {
            multiple: "",
            value: Array.from(Object.keys(_vm.validationsByGroup).keys())
          }
        },
        _vm._l(Object.keys(_vm.validationsByGroup), function(group, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c("v-expansion-panel-header", [
                _vm._v(
                  " " +
                    _vm._s(group) +
                    " (" +
                    _vm._s(
                      Array.isArray(_vm.validationsByGroup[group])
                        ? _vm.validationsByGroup[group].length
                        : ""
                    ) +
                    ") "
                )
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-list",
                    { attrs: { flat: "", "two-line": "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { multiple: "", "active-class": "" },
                          model: {
                            value: _vm.validationGroups,
                            callback: function($$v) {
                              _vm.validationGroups = $$v
                            },
                            expression: "validationGroups"
                          }
                        },
                        _vm._l(_vm.getValidationsInGroup(group), function(
                          validation,
                          j
                        ) {
                          return _c(
                            "v-list-item",
                            { key: j },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(validation.validationLabel) +
                                        " (" +
                                        _vm._s(validation.count) +
                                        ")"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("confirm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }